import styled from 'styled-components';

export const FundingBarStyles = styled.div`
  padding: 96px 0;

  @media (max-width: 768px) {
    padding: 64px 0;
  }

  .heading {
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1d2030;
    margin-bottom: 32px;
  }

  &.bg-light {
    background: #f9fbfe !important;
  }

  &.bg-blue {
    .heading {
      color: #ffffff;
    }
  }

  &.bg-charcoal {
    .heading {
      color: #ffffff;
    }
  }

  &.bg-midnight {
    .heading {
      color: #ffffff;
    }
  }

  img {
    max-height: 42px;

    @media (max-width: 1200px) {
      max-width: 200px;
    }

    @media (max-width: 991px) {
      max-width: 150px;
    }

    @media (max-width: 768px) {
      max-width: 100%;
      margin: 20px auto;
    }
  }
`;
